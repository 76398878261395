import React, { useEffect,useRef } from 'react';
import { useDispatch } from 'react-redux';

import CouponBtn from './CouponBtn';

import { useSelector } from '../../../store/store';

import { useOnScreen } from '../functions/useOnScreen';

import { detailPageView } from '../../../slice/views/viewSlice';
import { couldStartTrivia } from 'typescript';

import { setOfferRed } from '../../../slice/setOffer/setOfferSlice';

import {
    momentDateDiffcheck,
    momentMDFmt,
    reactTagManagerEvClickOfferFrame,
    reactTagManagerEvClickBasketItemFrame,
    reactTagManagerEvClickUsageHistoryItem,
    reactTagManagerEvClickWebCampaignDetail
} from '../functions/functions';

const Coupon:React.FC<{couponItemDataProp:any}> = ({couponItemDataProp}) => {
    const dispatch = useDispatch();
    const logoUrl:any = useSelector((state) => state.retailerData.logoUrl);
    const viewState:Number = useSelector((state) => state.viewData.categoryViewState);
    const viewObj:any = useSelector((state) => state.viewData);
    const auth:any = useSelector((state) => state.authentication);

    const targetRef = useRef(null);
    const componentView = useOnScreen(targetRef);
    // const componentView = useTestCustom();

    // useEffect(() => {
    //     if(viewState === 0 || viewState === 1){
    //         reactTagManagerEvViewOfferItem(viweData.id, auth.data.id);
    //     }

    //     if(viewState === 5){
    //         reactTagManagerEvViewBasketItem(viweData.id, auth.data.id);
    //     }

    //     if(viewState === 6){
    //         reactTagManagerEvViewUsageHistoryItem(viweData.id, auth.data.id);
    //     }

    // },[]);

    let viweData = {...couponItemDataProp};
    if(viweData['offer']){
        viweData = viweData['offer'];
    }

    const endDay = momentMDFmt(viweData.ended_at);
    const newFlag = momentDateDiffcheck(viweData.started_at) >= -7;
    const endFlag = momentDateDiffcheck(viweData.ended_at) < 0;
    const infiniteFlag = viweData.user_quota >= 2;
    let actionPayload = {
        categoryViewState: 2,
        category:viewObj.category,
        detail:viweData.id,
        detailStatus:'',
        preViewState:''
    };

    if(viweData.seted){
        actionPayload.detailStatus = 'seted';
    }

    if(viweData.used){
        actionPayload.detailStatus = 'used';
    }

    let clickEvent = () => {
        dispatch(detailPageView(actionPayload));
        dispatch(setOfferRed({offerId: '' ,offerStatus:'none'}));
        if(viewState === 0){
            reactTagManagerEvClickOfferFrame(viweData.id, auth.data.id);
        }

        if(viewState === 5){
            reactTagManagerEvClickBasketItemFrame(viweData.id, auth.data.id);
        }

        if(viewState === 6){
            reactTagManagerEvClickUsageHistoryItem(viweData.id, auth.data.id);
        }
    };

    if(viweData.webCampaign){
        clickEvent = () => {
            window.location.href = viweData.detail_url;
            reactTagManagerEvClickWebCampaignDetail(viweData.id, auth.data.id);
        }
    }

    if(momentDateDiffcheck(viweData.started_at) >= -7){
        return (
            <div className="coupon-list-item" ref={targetRef} data-id={viweData.id}>
                <div className="coupon">
                    <div className="toDetail" onClick={() => clickEvent()}>
                        <div className="retailer-logo">
                            <img src={logoUrl} alt="" />
                        </div>
                        <div className="coupon-info">
                            <div className="coupon-image">
                                <img src={viweData.picture_url} alt="" />
                            </div>
                            <div className="coupon-text">
                            <div className="coupon-use-info">
                                    <div className="validity-date">
                                        <p>{endDay}<span>まで</span></p>
                                    </div>
                                            <div className="coupon-use-info-left">
                                            {newFlag ? <div className="info"><p>NEW</p></div> : <></>}
                                                <div className="info">
                                                    {infiniteFlag ? <p>1何回でも</p> : <p>{viweData.user_quota}回のみ</p>}
                                                </div>
                                            </div>
                                            
                                        </div>
                                <div className="main-title flex justify-center">
                                {viweData.webCampaign ? <p className="campaign-title font-bold whitespace-nowrap overflow-ellipsis overflow-hidden webCampaign">{viweData.title}</p> : <><img className="h-6" src="images/waonA.png" alt="" /><p className="font-bold whitespace-nowrap overflow-ellipsis overflow-hidden">{viweData.discount_value.toLocaleString()}<span className="text-xs">pt</span></p></>}
                                </div>
                                <div className="sub-title">
                                    {viweData.webCampaign ? <p>{viweData.subtitle}</p> : <p>{viweData.title}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <CouponBtn couponBtnDataProp={viweData}/>
                </div>
                <img className="new-coupon" src={`${process.env.PUBLIC_URL}/images/new.svg`} alt="" />
                {
                    (viewState === 5 || viewState === 6) && !viweData.between ?
                    <div className='expired-coupon-mask' onClick={() => clickEvent()}>
                        <p>有効期限が<br />終了しました</p>
                    </div> :
                    <></>
                }
            </div>
        );
    }else{
        if(momentDateDiffcheck(viweData.ended_at) < 0 && (viewState === 5 || viewState === 6)){
            return (
                <div className="coupon-list-item" ref={targetRef} data-id={viweData.id}>
                    <div className="coupon">
                        <div className="toDetail" onClick={() => clickEvent()}>
                            <div className="retailer-logo">
                                <img src={logoUrl} alt="" />
                            </div>
                            <div className="coupon-info">
                                <div className="coupon-image">
                                    <img src={viweData.picture_url} alt="" />
                                </div>
                                <div className="coupon-text">
                                <div className="coupon-use-info">
                                    <div className="validity-date">
                                        <p>{endDay}<span>まで</span></p>
                                    </div>
                                            <div className="coupon-use-info-left">
                                            {newFlag ? <div className="info"><p>NEW</p></div> : <></>}
                                                <div className="info">
                                                    {infiniteFlag ? <p>1何回でも</p> : <p>{viweData.user_quota}回のみ</p>}
                                                </div>
                                            </div>
                                            
                                        </div>
                                    <div className="main-title flex justify-center">
                                    {viweData.webCampaign ? <p className="campaign-title font-bold whitespace-nowrap overflow-ellipsis overflow-hidden webCampaign">{viweData.title}</p> : <><img className="h-6" src="images/waonA.png" alt="" /><p className="font-bold whitespace-nowrap overflow-ellipsis overflow-hidden">{viweData.discount_value.toLocaleString()}<span className="text-xs">pt</span></p></>}
                                    </div>
                                    <div className="sub-title">
                                        {viweData.webCampaign ? <p>{viweData.subtitle}</p> : <p>{viweData.title}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CouponBtn couponBtnDataProp={viweData}/>
                    </div>
                    <div className='expired-coupon-mask' onClick={() => clickEvent()}>
                        <p>有効期限が<br />終了しました</p>
                    </div>
                </div>
            );
        }else{
            return (
                <div className="coupon-list-item" ref={targetRef} data-id={viweData.id}>
                    <div className="coupon">
                        <div className="toDetail" onClick={() => clickEvent()}>
                            <div className="retailer-logo">
                                <img src={logoUrl} alt="" />
                            </div>
                            <div className="coupon-info">
                                <div className="coupon-image">
                                    <img src={viweData.picture_url} alt="" />
                                </div>
                                <div className="coupon-text">
                                <div className="coupon-use-info">
                                    <div className="validity-date">
                                        <p>{endDay}<span>まで</span></p>
                                    </div>
                                            <div className="coupon-use-info-left">
                                            {newFlag ? <div className="info"><p>NEW</p></div> : <></>}
                                                <div className="info">
                                                    {infiniteFlag ? <p>1何回でも</p> : <p>{viweData.user_quota}回のみ</p>}
                                                </div>
                                            </div>
                                            
                                        </div>
                                    
                                    <div className="main-title flex justify-center">
                                    {viweData.webCampaign ? <p className="campaign-title font-bold whitespace-nowrap overflow-ellipsis overflow-hidden webCampaign">{viweData.title}</p> : <><img className="h-6" src="images/waonA.png" alt="" /><p className="font-bold whitespace-nowrap overflow-ellipsis overflow-hidden">{viweData.discount_value.toLocaleString()}<span className="text-xs">pt</span></p></>}
                                    </div>
                                    <div className="sub-title">
                                        {viweData.webCampaign ? <p>{viweData.subtitle}</p> : <p>{viweData.title}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CouponBtn couponBtnDataProp={viweData}/>
                    </div>
                </div>
            );
        }
    }
}

export default Coupon;