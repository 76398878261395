import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import axios from "axios";

import {data_category_items} from '../../data/data';

import {
    momentDateDiffcheck,
    momentMDFmt,
    reactTagManagerEvViewDetail,
    reactTagManagerEvClickSetBtnDetail,
    basketGet,
    replaceTxt
} from './functions/functions'

import { useSelector } from '../../store/store';

import { setOfferRed } from '../../slice/setOffer/setOfferSlice';
import { setErrorRed } from '../../slice/setError/setErrorSlice';
import "./Detail.css";
import {Fn200, preRequestFn, requestErrorFn, errorFn} from "./functions/errorfunctions";

const Detail:React.FC = () => {
    // Add a request interceptor
    axios.interceptors.request.use(preRequestFn, requestErrorFn);

// Add a response interceptor
    axios.interceptors.response.use(Fn200, errorFn);
    const dispatch = useDispatch();
    const selectedDetailStatus:string = useSelector((state) => state.viewData.detailStatus);
    const logoUrl:any = useSelector((state) => state.retailerData.logoUrl);
    const auth:any = useSelector((state) => state.authentication);
    const retailer:any = useSelector((state) => state.retailerData);
    const viewObj:any = useSelector((state) => state.viewData);

    const [btnState, setBtnState] = useState(selectedDetailStatus);
    const [data, setData] = useState<any>({});
    const [firstMount, setFirstMount] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const offerSet = async (id:string, accessToken:string, retailerId:string, offerId:string) => {
        const bearer = `Bearer ${accessToken}`;
        const headers:any = {
            'Content-Type':'application/json',
            'Cache-Control': 'no-cache',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY,
            'Authorization': bearer
        };
        const sendData = [{
            "retailer_id": retailerId,
            "offer_id": offerId
        }];

        try {
            const response = await axios({
                url: `${process.env.REACT_APP_MEMBERS_URL}/${id}/basket`,
                method:'POST',
                headers:headers,
                data:sendData,
                timeout: 15000
            });

            if(response.status === 200){
                setBtnState('seted');
                dispatch(setOfferRed({offerId: viewObj.detail ,offerStatus:'set'}));
            }else{
                dispatch(setErrorRed({errorStatus:'errorSet-basket'}));
            }
            return response.data;
        }
        catch (e:any) {
            dispatch(setErrorRed({errorStatus:'errorSet-basket'}));
            console.log(e);
        }
    };

    const setOffer = () => {
        offerSet(auth.data.id, auth.data.access_token, retailer.retailerId, viewObj.detail);
        reactTagManagerEvClickSetBtnDetail(viewObj.detail, auth.data.id);
    };

    const detailGet = async (id:string) => {
        const headers:any = {
            'Content-Type':'application/json',
            'Cache-Control': 'no-cache',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY
        };

        const basket = await basketGet(auth.data.id, auth.data.access_token, retailer.retailerId);
        if(Array.isArray(basket)){
            basket.map((basketItem) => {
                if(basketItem.offer.id === id ){
                    setBtnState('seted');
                }
            });
        }

        try {
            const startTime = new Date().getTime();
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/offers/${id}`,
                method:'GET',
                headers:headers
            });
            const elapsed = new Date().getTime() - startTime;
            console.log('Detail ' + elapsed);

            setData(response.data);
        }
        catch (e:any) {
            dispatch(setErrorRed({errorStatus:'error-offer'}));
            console.log(e);
        }
    };

    useEffect(() => {
        detailGet(viewObj.detail);
        setFirstMount(true);
    },[]);

    useEffect(() => {
        if(firstMount){
            reactTagManagerEvViewDetail(viewObj.detail, auth.data.id);
        }
    },[firstMount]);

    if('id' in data){
        const startDay = momentMDFmt(data.started_at);
        const endDay = momentMDFmt(data.ended_at);
        const newFlag = momentDateDiffcheck(data.started_at) >= -7;
        const endFlag = momentDateDiffcheck(data.ended_at) < 0;
        const infiniteFlag = data.user_quota >= 2;
        let webCampaign = false;
        let second_picture = '';
        if ('secondary_picture_url' in data && 
            data.secondary_picture_url !== null && 
            data.secondary_picture_url !== undefined && 
            data.secondary_picture_url !== '') {
            second_picture = data.secondary_picture_url;
        }

        if('categories' in data && Array.isArray(data.categories) ){
            data.categories.map((cat:any) => {
                if(cat.name === data_category_items[0].param){
                    webCampaign = true;
                }
            });
        }

        if(webCampaign || endFlag){
            return (
                <>
                    {endFlag ?
                    <div className="expired-detail-information">
                        <div className="information-text">
                            ご指定のクーポンは有効期限のため無効となっております。他のクーポンをご利用ください。
                        </div>
                    </div> :
                    <></>}
                    <div className="coupon-detail">
                        <div className="coupon">
                            <div className="coupon-top">
                                <div className="retailer-logo">
                                    <img src={logoUrl} alt="" />
                                </div>
                            </div>
                            <div className="coupon-info">
                                <div className="coupon-image">
                                    <img src={data.picture_url} alt="" />
                                </div>
                                <div className="coupon-text">
                                    <div className="coupon-use-info">
                                        <div className="coupon-use-info-left">
                                            {newFlag ? <div className="info"><p>NEW</p></div> : <></>}
                                            <div className="info">
                                                {infiniteFlag ? <p>何回でも</p> : <p>{data.user_quota}回のみ</p>}
                                            </div>
                                        </div>
                                        <div className="validity-date">
                                            <p>有効期間：{startDay}～{endDay}</p>
                                        </div>
                                    </div>
                                    <div className="main-title">
                                        <img src="images/waonA.png" alt="" />
                                        <p>{data.discount_value.toLocaleString()}<span>pt</span></p>
                                    </div>
                                    <div className="sub-title">
                                        <p>{data.title}</p>
                                    </div>
                                    <div className="detail-infomation">
                                        <div dangerouslySetInnerHTML={{ __html: replaceTxt(data.overview_html) }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceTxt(data.disclaimer_html) }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }else{
            if(viewObj.detailStatus !== 'used'){
                return (
                    <>
                        <div className="coupon-detail">
                            <div className="coupon">
                                <div className="coupon-top">
                                    <div className="retailer-logo">
                                        <img src={logoUrl} alt="" />
                                    </div>
                                </div>
                                <div className="coupon-info">
                                    <div className="coupon-image">
                                        <img src={data.picture_url} alt="" />
                                    </div>
                                    <div className="coupon-text">
                                        <div className="coupon-use-info">
                                            <div className="coupon-use-info-left">
                                                {newFlag ? <div className="info"><p>NEW</p></div> : <></>}
                                                <div className="info">
                                                    {infiniteFlag ? <p>何回でも</p> : <p>{data.user_quota}回のみ</p>}
                                                </div>
                                            </div>
                                            <div className="validity-date">
                                                <p>有効期間：{startDay}～{endDay}</p>
                                            </div>
                                        </div>
                                        <div className="main-title">
                                            <img src="images/waonA.png" alt="" />
                                            <p>{data.discount_value.toLocaleString()}<span>pt</span></p>
                                        </div>
                                        <div className="sub-title">
                                            <p>{data.title}</p>
                                        </div>
                                        {second_picture ? <div className="coupon-image">
                                            <img src={second_picture} alt="" />
                                        </div> : <></>}
                                        <div className="detail-infomation">
                                            <div dangerouslySetInnerHTML={{ __html: replaceTxt(data.overview_html) }}></div>
                                            <div dangerouslySetInnerHTML={{ __html: replaceTxt(data.disclaimer_html) }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {btnState !== 'seted' ?
                        <button className="coupon-button" disabled={loading} onClick={()=>{
                            setLoading(true);
                            setOffer();
                        }}>
                            <span>
                                <img src="images/icon-set.svg" alt="" /> セット
                            </span>
                        </button> :
                        <button className="coupon-button setted-button">
                            <span>
                                <img src="images/icon-setted.svg" alt="" /> セット済
                            </span>
                        </button>}
                    </>
                );
            }else{
                return (
                    <>
                        <div className="coupon-detail">
                            <div className="coupon">
                                <div className="coupon-top">
                                    <div className="retailer-logo">
                                        <img src={logoUrl} alt="" />
                                    </div>
                                </div>
                                <div className="coupon-info">
                                    <div className="coupon-image">
                                        <img src={data.picture_url} alt="" />
                                    </div>
                                    <div className="coupon-text">
                                        <div className="coupon-use-info">
                                            <div className="coupon-use-info-left">
                                                {newFlag ? <div className="info"><p>NEW</p></div> : <></>}
                                                <div className="info">
                                                    {infiniteFlag ? <p>何回でも</p> : <p>{data.user_quota}回のみ</p>}
                                                </div>
                                            </div>
                                            <div className="validity-date">
                                                <p>有効期間：{startDay}～{endDay}</p>
                                            </div>
                                        </div>
                                        <div className="main-title">
                                            <img src="images/waonA.png" alt="" />
                                            <p>{data.discount_value.toLocaleString()}<span>pt</span></p>
                                        </div>
                                        <div className="sub-title">
                                            <p>{data.title}</p>
                                        </div>
                                        <div className="detail-infomation">
                                            <div dangerouslySetInnerHTML={{ __html: replaceTxt(data.overview_html) }}></div>
                                            <div dangerouslySetInnerHTML={{ __html: replaceTxt(data.disclaimer_html) }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="coupon-button setted-button">
                            <span>
                                利用済
                            </span>
                        </div>
                    </>
                );
            }
        }
    }else{
        return <></>
    }
};

export default Detail;